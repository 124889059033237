* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body,
input[type="text"],
input[type="submit"] {
  font-family: "Gloria Hallelujah", cursive;
}

body {
  font-size: 2em;
}

@keyframes gradient {
  from {
    background-position: 0% 50%;
  }
  25% {
    background-position: 100% 25%;
  }
  50% {
    background-position: 50% 50%;
  }
  75% {
    background-position: 100% 25%;
  }
  to {
    background-position: 0% 50%;
  }
}

.container {
  text-align: center;
  padding: 10px;
  perspective: 3000px;

  background: linear-gradient(-45deg, #b40101, #e73c7e, #23a6d5, #23d5ab);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
  // height: 100vh;

  > * {
    margin: 25px 10px;
    padding: 10px;
    border: 5px solid #000;
    border-radius: 25%;
    background-color: white;
    box-shadow: 5px 5px 5px #666;

    position: relative;
    transform: rotateY(-2deg);
    transition: all 1s;
  }

  > *:hover {
    transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);
    border-radius: 0;
  }

  > img {
    display: block;
    width: 50%;
  }

  input {
    font-size: 1em;
    padding: 5px;
    margin: 15px;
    max-width: 100%;
    border: 5px solid #000;
    border-radius: 25%;
  }

  input[type="submit"] {
    background-color: #b4010122;
    cursor: pointer;
    margin-left: 10px;
  }

  input[type="submit"]:hover {
    background-color: #b4010166;
  }

  .social {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .social a {
    margin-left: 10px;
  }

  h1,
  .person,
  .video {
    display: inline-block;
  }

  .wrap {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    padding: 20px;
  }

  .person img {
    max-width: 100%;
    border-radius: 33%;
  }

  .video iframe {
    border-radius: 17%;
    margin-top: 15px;
    max-width: 100%;
  }

  .person img.real {
    width: 338px;
  }

  .person img.sketch {
    height: 338px;
  }

  .person img,
  .video iframe,
  input {
    transition: border-radius 1s;
  }

  .person:hover img,
  .video:hover iframe,
  input:hover {
    border-radius: 0;
  }

  .video .title {
    max-width: 560px;
  }

  h1 {
    margin: 45px 0 15px;
  }

  > h1 {
    margin-left: auto;
    margin-right: auto;
  }

  .thanks {
    margin-bottom: 10%;
  }
}

.highlight {
  cursor: pointer;
  position: relative;
  padding: 0 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  transition: all 1s;
}

.highlight:after,
.highlight:before {
  content: " ";
  width: 10px;
  height: 10px;
  position: absolute;
  border: 0px solid #fff;
  transition: all 1s;
}

.highlight:after {
  top: -1px;
  left: -1px;
  border-top: 5px solid #b40101;
  border-left: 5px solid #b40101;
}

.highlight:before {
  bottom: -1px;
  right: -1px;
  border-bottom: 5px solid #b40101;
  border-right: 5px solid #b40101;
}

.highlight:hover {
  border-top-right-radius: 0px;
  border-bottom-left-radius: 0px;
}

.highlight:hover:before,
.highlight:hover:after {
  width: 100%;
  height: 100%;
}

.header {
  overflow: hidden;

  img.logo {
    width: 100%;
    padding-top: 7%;
  }

  img.dm {
    width: 30%;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
    z-index: -1;
  }

  .players {
    position: absolute;
    top: 70%;
  }

  img.player {
    width: 20%;
    vertical-align: bottom;
  }

  img.bounce,
  img.bounce:hover {
    animation: 1s ease-out 0s 1;
    backface-visibility: hidden;
  }

  img.dm {
    animation-name: slideInFromTop;
    animation-duration: 1s;
  }
  img.player.i {
    animation-name: slideInFromLeft;
    animation-duration: 1.4s;
  }
  img.player.y {
    animation-name: slideInFromBottom;
    animation-duration: 1.2s;
  }
  img.player.k {
    animation-name: slideInFromRight;
    animation-duration: 1.6s;
  }
}
@mixin down($property) {
  0% {
    transform: #{$property }(-100%);
  }

  12% {
    transform: #{$property }(-90%);
  }

  24% {
    transform: #{$property }(-57%);
  }

  36% {
    transform: #{$property }(-2%);
  }

  54% {
    transform: #{$property }(-25%);
  }

  74% {
    transform: #{$property }(-2%);
  }

  82% {
    transform: #{$property }(-7%);
  }

  92% {
    transform: #{$property }(-1%);
  }

  96% {
    transform: #{$property }(-2%);
  }

  100% {
    transform: #{$property }(0);
  }
}

@mixin up($property) {
  0% {
    transform: #{$property }(100%);
  }

  12% {
    transform: #{$property }(90%);
  }

  24% {
    transform: #{$property }(57%);
  }

  36% {
    transform: #{$property }(2%);
  }

  54% {
    transform: #{$property }(25%);
  }

  74% {
    transform: #{$property }(2%);
  }

  82% {
    transform: #{$property }(7%);
  }

  92% {
    transform: #{$property }(1%);
  }

  96% {
    transform: #{$property }(2%);
  }

  100% {
    transform: #{$property }(0);
  }
}

@keyframes slideInFromLeft {
  @include down(translateX);
}

@keyframes slideInFromTop {
  @include down(translateY);
}

@keyframes slideInFromBottom {
  @include up(translateY);
}

@keyframes slideInFromRight {
  @include up(translateX);
}
